import React from "react";
import logo from "./asm-icon.jpeg";
import "./LogoAnimation.css";

function Header() {
  return (
    <header className="flex justify-between items-center p-6 bg-gray-800">
      <div className="flex items-center text-2xl font-bold">
        <img
          src={logo}
          alt="Logo"
          className="logo h-10 w-10 mr-2"
          style={{ borderRadius: "25px" }}
        />
        ASM Software
      </div>
      <nav className="space-x-4">
        <a href="#" className="hover:text-pink-500">
          Home
        </a>
        <a href="#" className="hover:text-pink-500">
          About
        </a>
        <a href="#" className="hover:text-pink-500">
          Projects
        </a>
        <a href="#" className="hover:text-pink-500">
          Contact
        </a>
      </nav>
      <button className="bg-pink-500 hover:bg-pink-700 text-white font-bold py-2 px-4 rounded">
        Get Started
      </button>
    </header>
  );
}

export default Header;
