import React from "react";

function Projects() {
  return (
    <section className="p-20 bg-gray-800">
      <h2 className="text-4xl font-bold mb-10 text-center">Projects</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10">
        <div className="bg-gray-700 p-6 rounded-lg">
          <img
            src="project-image1.jpg"
            alt="Project 1"
            className="w-full h-40 object-cover mb-4 rounded-lg"
          />
          <h3 className="text-2xl font-bold mb-2">Project 1</h3>
          <p>
            Description for project 1. Highlight the key aspects and
            technologies used.
          </p>
        </div>
        <div className="bg-gray-700 p-6 rounded-lg">
          <img
            src="project-image2.jpg"
            alt="Project 2"
            className="w-full h-40 object-cover mb-4 rounded-lg"
          />
          <h3 className="text-2xl font-bold mb-2">Project 2</h3>
          <p>
            Description for project 2. Highlight the key aspects and
            technologies used.
          </p>
        </div>
        <div className="bg-gray-700 p-6 rounded-lg">
          <img
            src="project-image3.jpg"
            alt="Project 3"
            className="w-full h-40 object-cover mb-4 rounded-lg"
          />
          <h3 className="text-2xl font-bold mb-2">Project 3</h3>
          <p>
            Description for project 3. Highlight the key aspects and
            technologies used.
          </p>
        </div>
      </div>
    </section>
  );
}

export default Projects;
