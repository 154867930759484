import React from "react";

function Hero() {
  return (
    <section className="p-20 text-center bg-gray-900">
      <h1 className="text-5xl font-bold mb-6">Innovations and Solutions</h1>
      <p className="text-lg mb-6">
        Here goes the description about the fullstack development expertise.
        This section should highlight the key points and capabilities.
      </p>
      <div className="space-x-4">
        <button className="bg-pink-500 hover:bg-pink-700 text-white font-bold py-2 px-4 rounded">
          Learn More
        </button>
        <button className="bg-pink-500 hover:bg-pink-700 text-white font-bold py-2 px-4 rounded">
          Contact
        </button>
      </div>
    </section>
  );
}

export default Hero;
