import React from "react";

function Footer() {
  return (
    <footer className="p-6 bg-gray-800 text-center">
      <p>&copy; All rights reserved by ASM Software</p>
    </footer>
  );
}

export default Footer;
